import React from 'react';
import FetchApiService from "../../services/api_call";

class Footer extends React.Component {

    constructor() {
        super();
        this.state = {
            logos: []
        };
    }

    componentDidMount() {
        (async () => {
            const logos=await FetchApiService.getAllLogos();
            this.setState({
                logos: logos.data
            });
        })()
    }

    render() {
        return(
            <React.Fragment>
                <footer>
                    <ul>
                        {this.state.logos.map((logo, index) => (
                            <div key={logo.id} className={"logoLink"}>
                                <a href={logo.logo_lien} target={"_blank"} rel="noreferrer">
                                    <img src={logo.logo_file.sizes["1536x1536"]} alt={ "Logo " + logo.title}/>
                                </a>
                            </div>
                        ))}
                    </ul>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;