import React from 'react';
import Home from "./pages/Home";

class Root extends React.Component {

    render() {
        return(
            <React.Fragment>
                <Home { ... this.props } />
            </React.Fragment>
        );
    }
}

export default Root;