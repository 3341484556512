import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames/bind';
import FetchApiService from "../../services/api_call";
import { ReactComponent as Arrow } from '../../img/arrow.svg';
import PhotosType from "../widgets/PhotosType"
import PhotosCat from "../widgets/PhotosCat"

class Photos extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        (async () => {
            const items=await FetchApiService.getAllPhotosItems();
            this.setState({
                items: items.data
            });
        })()
    }

    getNavClassNames(taille_typo) {
        return classNames({
            'photosNav': true,
            'photosNavVisible': this.props.params.photosItem ? false : true,
        });
    }

    getPhotoTypesClassNames() {
        return classNames({
            'photosItem': true,
            'photosItemVisible': (this.props.params.photosItem && !this.props.params.photosCat ? true : false ),
        });
    }

    getPhotoCatClassNames() {
        return classNames({
            'photosCat': true,
            'photosCatVisible': this.props.params.photosCat ? true : false,
        });
    }

    render() {
        return(
            <React.Fragment>
                <div className={this.getNavClassNames()}>
                {
                    this.state.items ?
                        this.state.items.map((item, index) => (
                            <div className={"photosNavItem"} key={index} >
                                <Link to={ '/realisations/photos/' + item.title.toLowerCase() + "/" + item.slug }>
                                <div className={"photosNavItemTop"} >
                                    <h2>{ item.title }</h2>
                                    <h3>{ item.baseline }</h3>
                                    <p>{ item.description }</p>
                                </div>
                                <div className={"photosNavItemBottom"} >
                                    <div className="photosNavItemBottomWrapper">
                                        <img className="photosNavItemBottomIllu" src={item.image} alt={item.title}/>
                                        <Arrow/>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        ))
                        :
                        <div>nop</div>
                }
                </div>
                <div className={this.getPhotoTypesClassNames()}>
                    <PhotosType { ... this.props }/>
                </div>
                <div className={this.getPhotoCatClassNames()}>
                    <PhotosCat { ... this.props }/>
                </div>
            </React.Fragment>
        );
    }
}

export default Photos;