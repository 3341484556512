import React from 'react';

class ListShortVideos extends React.Component {

    constructor() {
        super();
        this.state = {
            shortVideos: []
        };
    }

    loadShortVideos(video){
        this.props.showShortVideos(video.video_courte_fichier)
        this.props.setThumbnail(video.video_courte_thumbnail)
    }

    render() {
        return(
            <React.Fragment>
                <ul className="listShortVideos">
                    {
                    this.props.videos.length > 0 ?
                        this.props.videos.map((video, index) => (
                            <li key={video.video_courte_thumbnail.ID} onClick={  () => this.loadShortVideos(video)}>
                                <img src={video.video_courte_thumbnail.sizes.medium} alt={video.video_courte_description}/>
                            </li>
                        ))
                    :
                        ""
                    }
                </ul>
            </React.Fragment>
        );
    }
}

export default ListShortVideos;