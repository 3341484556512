import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ReactComponent as Arrow } from '../../img/next-prev.svg';
import FetchApiService from "../../services/api_call";
import CatDescription from "./CatDescription";

class PhotoSlider extends React.Component {


    constructor() {
        super();
        this.state = {
            items: [],
            prevPhotoCat: ""
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.items[1] || ( this.props.params.photosCat !== this.state.prevPhotoCat )) {
            this.getItems();
        }
    }

    getItems() {
            (async () => {
                const items=await FetchApiService.getPhotosListByType(this.props.params.photosCat);
                this.setState({
                    items: items.data,
                    prevPhotoCat: this.props.params.photosCat
                });
            })()
    }

    render() {

        const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-prev slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
                type="button"
            >
                <Arrow/>
            </button>
        );
        const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-next slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
                type="button"
            >
                <Arrow/>
            </button>
        );

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            nextArrow: <SlickArrowRight/>,
            prevArrow: <SlickArrowLeft/>,
            responsive: [
                {
                    breakpoint: 480,
                    settings: "unslick"
                }
            ]
        };


        return(
            <React.Fragment>
                {
                    this.state.items.length > 0  ?
                        <Slider {...settings}>
                            <CatDescription { ... this.props } />
                            {
                                this.state.items[1].map((item, index) => (
                                <div key={index} className={"photoSliderItem"}>
                                    { item.photo_slider ?
                                        <img
                                            src={item.photo_slider.sizes["items_sliders_photos"]}
                                            width={item.photo_slider.sizes["items_sliders_photos-width"]}
                                            height={item.photo_slider.sizes["items_sliders_photos-height"]}
                                            alt=""/>
                                        :
                                        <div></div>
                                    }
                                </div>
                                ))
                            }
                        </Slider >
                        :
                        <div></div>
                }
            </React.Fragment>
        );
    }
}

export default PhotoSlider;