import React from 'react';
import Footer from "../widgets/Footer";
import FetchApiService from "../../services/api_call";


class Agence extends React.Component {

    constructor() {
        super();
        this.state = {
            page: ""
        };
    }

    componentDidMount() {
        (async () => {
            const page=await FetchApiService.getPageBySlug('agence');
            this.setState({
                page: page.data
            });
        })()
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.state.page ?
                        <React.Fragment>
                            <h1 dangerouslySetInnerHTML={{__html: this.state.page.accroche}}></h1>
                            <div className="page_content">
                                <p dangerouslySetInnerHTML={{__html: this.state.page.content}}>
                                </p>
                            </div>
                        </React.Fragment>
                    :
                        ""
                }
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Agence;