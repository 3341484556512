import React from 'react';
import { useParams } from "react-router-dom";
import Realisations from "./pages/Realisations";
import Videos from "./pages/Videos";
import Photos from "./pages/Photos";
import Contact from "./pages/Contact";
import Agence from "./pages/Agence";
import classNames from 'classnames/bind';

class ContentWP extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
    }

    getRealisationsClassNames() {
        return classNames({
            'realisationsWrapper': true,
            'visible': this.props.realisations,
        });
    }

    getVideosClassNames() {
        return classNames({
            'videosWrapper': true,
            'visible': this.props.videos,
            'splitted': this.props.params.videosId ? true : false
        });
    }

    getPhotosClassNames() {
        return classNames({
            'photosWrapper': true,
            'visible': this.props.photos,
            'splitted': this.props.params.photosId ? true : false
        });
    }

    getContactClassNames() {
        return classNames({
            'pageWrapper': true,
            'pageContact': true,
            'visible': this.props.contact,
        });
    }

    geAgenceClassNames() {
        return classNames({
            'pageWrapper': true,
            'pageAgence': true,
            'visible': this.props.agence,
        });
    }

    render() {
        return(
            <React.Fragment>
                <main className={"contentWrapper"}>
                    <React.Fragment>
                        <div className={this.getRealisationsClassNames()}>
                            <Realisations { ... this.props }/>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className={this.getVideosClassNames()}>
                            <Videos { ... this.props }/>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className={this.getPhotosClassNames()}>
                            <Photos { ... this.props }/>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                            <div className={this.getContactClassNames()}>
                                <Contact { ... this.props }/>
                            </div>
                    </React.Fragment>
                    <React.Fragment>
                            <div className={this.geAgenceClassNames()}>
                                <Agence { ... this.props }/>
                            </div>
                    </React.Fragment>
                </main>
            </React.Fragment>
        );
    }
}

export default function Content(props){
    const params = useParams()
    return (<ContentWP params={params}  { ... props }/>)
}