import React from 'react';
import VideosNav from "../widgets/VideosNav";
import VideosContent from "../widgets/VideosContent";
import classNames from 'classnames/bind';
import VideoPlayer from "../widgets/VideoPlayer"
import { ReactComponent as CloseLogo } from '../../img/close.svg';
import FetchApiService from "../../services/api_call";

class Videos extends React.Component {

    constructor() {
        super();
        this.state = {
            videos: [],
            isPanelOpen: false,
            bigVideoIsOpen: false,
            videoFile: "",
            videoTitle: "",
            videoDirector: "",
            clients: [],
            clientId: 0
        };
        this.loadVideo = this.loadVideo.bind(this);
        this.triggerCloseViewer = this.triggerCloseViewer.bind(this);
    }

    componentDidMount() {
        (async () => {
            const clients=await FetchApiService.getAllClients();
            clients.data.sort((a, b) => (a.client_poids > b.client_poids) ? 1 : -1)
            this.setState({
                clients: clients.data
            });
        })()
    }


    getNavClassNames(taille_typo) {
        return classNames({
            'videosNav': true,
            'splitted': this.props.params.videosId ? true : false,
            'bigVideoIsOpen': this.state.bigVideoIsOpen
        });
    }

    getContentClassNames(taille_typo) {
        return classNames({
            'videosContent': true,
            'splitted': this.props.params.videosId ? true : false,
            'bigVideoIsOpen': this.state.bigVideoIsOpen
        });
    }

    getVideoClassNames(taille_typo) {
        return classNames({
            'bigVideoViewer': true,
            'bigVideoIsOpen': this.state.bigVideoIsOpen
        });
    }

    loadVideo(video){
        (async () => {
            const file=await FetchApiService.getFileById(video);
            const details=await FetchApiService.getDetailByFileId(video);
            this.setState({
                videoFile: file.data,
                videoTitle: details.data.title,
                videoDirector: details.data.video_director
            });
        })()
    }

    triggerCloseViewer(video){
        this.setState({
            bigVideoIsOpen: ! this.state.bigVideoIsOpen
        });
    }

    render() {
        return(
            <React.Fragment>
                <div className={this.getNavClassNames()}>
                    <VideosNav { ... this.props } clients={this.state.clients}/>
                </div>
                <div className={this.getContentClassNames()}>
                    <VideosContent loadVideo={  (id) => this.loadVideo(id)} triggerCloseViewer={() => this.triggerCloseViewer()} { ... this.props } clients={this.state.clients}/>
                </div>
                <div className={this.getVideoClassNames()}>
                    <VideoPlayer videoFile={this.state.videoFile}/>
                    <div className="videoDescription">
                        <div className="videoDescriptionTitle">
                            {this.state.videoTitle}
                        </div>
                        <div className="videoDescriptionDirctor">
                            {this.state.videoDirector}
                        </div>
                    </div>
                    <div className="closeLogo" onClick={() => this.triggerCloseViewer()}><CloseLogo /></div>
                </div>
            </React.Fragment>
        );
    }
}

export default Videos;