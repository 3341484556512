import React from 'react';
import FetchApiService from "../../services/api_call";
import PhotosTypeTabs from "./PhotosTypeTabs"
import PhotosCaroussel from "./PhotosCaroussel"

class PhotosType extends React.Component {

    constructor() {
        super();
        this.state = {
            items: []
        };
        this.getItems = this.getItems.bind(this);
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.params.photosType !== prevProps.params.photosType) {
            this.getItems();
        }
    }

    getItems() {
        if (  this.props.params.photosType ) {
            (async () => {
                const items=await FetchApiService.getAllPhotosByType(this.props.params.photosType);
                this.setState({
                    items: items.data
                });
            })()
        }
    }

    render() {
        return(
            <React.Fragment>
                        <React.Fragment>
                        <div className={"typeTabs"}>
                            <PhotosTypeTabs { ... this.props  }/>
                        </div>
                        <div className={"photoCaroussel"}>
                            {this.state.items ?
                            <PhotosCaroussel { ... this.props } photos={this.state.items}/>
                                :
                                <div></div>
                            }
                        </div>
                        </React.Fragment>
            </React.Fragment>
        );
    }
}

export default PhotosType;