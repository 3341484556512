import axios from 'axios';
import configData from "../config.json";

const FetchApiService = {

    getAllVideos(){
        return axios.get(configData.SERVER_URL + '/wp-json/wp/v2/video')
    },

    getVideoById(id) {
        return axios.get(configData.SERVER_URL + '/wp-json/wp/v2/video/' + id)
    },

    getFileById(id) {
        return axios.get(configData.SERVER_URL + '/wp-json/wp/v2/media/' + id);
    },

    getPageBySlug(slug) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/page/' + slug);
    },

    getAllClients(){
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/clients')
    },

    getAllLogos(){
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/logos')
    },

    getVideosByClient(slug){
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/videos/client/' + slug)
    },

    getClientSiblings(slug){
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/siblings/client/' + slug)
    },

    getDetailByFileId(id) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/details/file/' + id)
    },

    getAllPhotosItems() {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/photos/items')
    },

    getAllTypesByItem(item) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/types/item/' + item)
    },

    getAllPhotosByType(type) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/photos/types/' + type)
    },

    getPhotosListByType(cat) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/photolist/cat/' + cat)
    },

    getBigVideo(cat) {
        return axios.get(configData.SERVER_URL + '/wp-json/abyss_api/v1/videofront')
    }


};

export default FetchApiService;