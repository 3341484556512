import React from 'react';
import Root from './Root'
import Content from './Content'

class Site extends React.Component {

    render() {
        return(
            <React.Fragment>
                <div className={"siteWrapper"}>
                    <Root { ... this.props } />
                    <Content { ... this.props } />
                </div>
            </React.Fragment>
        );
    }
}

export default Site;