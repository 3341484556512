import React from 'react';
import FetchApiService from "../../services/api_call";
import {Link} from "react-router-dom";
import VideoItem from "./VideoItem"

class VideosContent extends React.Component {

    constructor() {
        super();
        this.state = {
            videos: [],
            siblings: [],
            clientID: ""
        };
    }

    componentDidMount() {
        this.getItems();
    }


    componentDidUpdate() {
        this.getItems();
    }

    getItems() {
        if (  this.props.params.videosId && ( this.state.clientID !== this.props.params.videosId) ) {
            (async () => {
                const videos=await FetchApiService.getVideosByClient(this.props.params.videosId);
                const siblings=await FetchApiService.getClientSiblings(this.props.params.videosId);
                this.setState({
                    videos: videos.data,
                    siblings: siblings.data,
                    clientID: this.props.params.videosId
                });
            })()
        }
    }


    render() {
        return(
            <React.Fragment>
                {this.state.videos.map((video, index) => (
                    <div key={video.id} className={"fullVideoContent"}>
                        <VideoItem
                        fileId={video}
                        description={video.videos_description}
                        distribution={video.video_distribution}
                        thumbnail={video.videos_thumbnail}
                        versions_courtes={video.versions_courtes}
                        show_versions_courtes={video.show_versions_courtes}
                        title={video.title}
                        key={video.id}
                        loadVideo={this.props.loadVideo}
                        triggerCloseViewer={ () => this.props.triggerCloseViewer()}
                        />
                        { this.state.siblings ?
                            <div className={"mobileSiblingsMenu"}>
                                <div>
                                {this.state.siblings.prev ?
                                    <div>
                                        <Link to={ '/realisations/' + this.state.siblings.prev.slug } className={"mobileSiblingsMenuPrev"}>
                                            <div>
                                                <i className={"mobileSiblingsMenuNextLogo"}>
                                                    &#60;
                                                </i>
                                            </div>
                                            <div>
                                                { this.state.siblings.prev.name }
                                            </div>
                                        </Link>
                                    </div>
                                    :
                                    <div> </div>
                                }
                                <div>
                                    <Link to={ '/' } className={"mobileSiblingsMenuHome"}>
                                        <span>
                                            RETOUR À L’ACCUEIL
                                        </span>
                                    </Link>
                                </div>
                                {this.state.siblings.next ?
                                    <div>
                                        <Link to={ '/realisations/' + this.state.siblings.next.slug } className={"mobileSiblingsMenuNext"}>
                                            <div>
                                                { this.state.siblings.next.name}
                                            </div>
                                            <div>
                                                <i className={"mobileSiblingsMenuNextLogo"}>
                                                    &#62;
                                                </i>
                                            </div>
                                        </Link>
                                    </div>
                                    :
                                    <div></div>
                                }
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                ))}

            </React.Fragment>
        );
    }
}

export default VideosContent;