import React from 'react';
import { Link } from "react-router-dom";
import FetchApiService from "../../services/api_call";

class PhotosCaroussel extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
            prevPhotoCat: "",
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.params.photosType !== prevProps.params.photosType) {
            this.getItems();
        }
    }

    getItems() {
        if (  this.props.params.photosType ) {
            (async () => {
                const items=await FetchApiService.getAllPhotosByType(this.props.params.photosType);
                console.log("Caroussel",items.data );
                this.setState({
                    items: items.data
                });
            })()
        }
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.state.items.length > 0?
                        <ul className={"catCAroussel"}>
                            {
                                this.state.items.map((item, index) => (
                                    <li key={index}>
                                        {
                                            item.map((cat, cat_index) => (
                                                cat.tuile_type === 'title' ?
                                                <div key={cat_index} className={"tuileTitle"}>
                                                    <Link to={ '/realisations/photos/' +
                                                    this.props.params.photosItem + "/"
                                                    + this.props.params.photosType+ "/"
                                                    + cat.slug
                                                    }>
                                                        <h3>
                                                        <span dangerouslySetInnerHTML={{__html: cat.photo_categories}}>
                                                        </span>
                                                        </h3>
                                                        <h4>{cat.photo_types}</h4>
                                                    </Link>
                                                </div>
                                                :
                                                <div key={cat_index} className={"tuileImage"}>
                                                    <Link to={ '/realisations/photos/' +
                                                    this.props.params.photosItem + "/"
                                                    + this.props.params.photosType+ "/"
                                                    + cat.slug
                                                    }>
                                                    <img src={ cat.photo_miniature.sizes.items_square_photos } alt=""/>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                        :
                        <div></div>
                }
            </React.Fragment>
        );
    }
}

export default PhotosCaroussel;