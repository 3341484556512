import ReactDOM from "react-dom/client";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './style.scss';
import Site from './components/Site'


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<Site home />}/>
        <Route path="realisations" element={<Site  realisations />} />
        <Route path="realisations/videos" element={<Site videos />}>
            <Route path=":videosId" element={<Site videos />} />
        </Route>
        <Route path="realisations/photos" element={<Site photos />}>
            <Route path=":photosItem/:photosType" element={<Site  />} />
            <Route path=":photosItem/:photosType/:photosCat" element={<Site  />} />
        </Route>
        <Route path="contact" element={<Site  contact />} />
        <Route path="agence" element={<Site agence />} />
    </Routes>
  </BrowserRouter>
);