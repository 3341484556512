import React from 'react';
import { Link } from "react-router-dom";
import Counter from "../widgets/Counter";
import BrandLogo from '../../img/abyss.gif'
import { ReactComponent as TopLeftFrame } from '../../img/frame/top_left.svg';
import { ReactComponent as TopRightFrame } from '../../img/frame/top_right.svg';
import { ReactComponent as BottomLeftFrame } from '../../img/frame/bottom_left.svg';
import { ReactComponent as BottomRightFrame } from '../../img/frame/bottom_right.svg';
import { ReactComponent as LogoSimplified } from '../../img/abyss_a.svg';
import { ReactComponent as BurgerMenu } from '../../img/burger_menu.svg';
import classNames from 'classnames/bind';
import FetchApiService from "../../services/api_call";

class Home extends React.Component {

   constructor() {
        super();
        this.state = {
            end_animation: false,
            logo_on: false,
            frame_on: false,
            mobile_menu_visible: false,
            show_big_on_front: false,
            mobile_menu: false,
            hide_big_video: false,
            bigVideo: {}
        };
    }

    componentDidMount() {

        (async () => {
            const items=await FetchApiService.getBigVideo();
            this.setState({
                bigVideo: items.data
            });
        })()

        setTimeout(function() {
            this.setState({
                frame_on: true
            })
        }.bind(this), 500)
        setTimeout(function() {
            this.setState({
                logo_on: true
            })
        }.bind(this), 1500)
        setTimeout(function() {
            this.setState({
                end_animation: true,
                mobile_menu_visible: true,
                frame_on: false
            })
        }.bind(this), 4000)
    }

    getBrandClassNames(taille_typo) {
        return classNames({
            'brand_logo': true,
            'top_left': this.state.end_animation,
            'logo_on': this.state.logo_on,
            'home': this.props.home,
            'page': ! this.props.home
        });
    }

    getFrameClassNames(taille_typo) {
        return classNames({
            'frame': true,
            'frame_on': this.state.frame_on,
            'home': this.props.home,
            'page': ! this.props.home
        });
    }

    getNavClassNames() {
        return classNames({
            'visible': true,
            'big_on_front': this.state.show_big_on_front,
            'home': this.props.home,
            'page': ! this.props.home
        });
    }

    getAsideClassNames() {
        return classNames({
            'visible': true,
            'home': this.props.home,
            'mobile_menu_is_open': this.state.mobile_menu,
            'page': ! this.props.home
        });
    }

    getMobileMenuClassNames() {
        return classNames({
            'mobile_menu': true,
            'mobile_menu_is_visible': this.state.mobile_menu_visible,
            'mobile_menu_is_open': this.state.mobile_menu
        });
    }

    getBigVideoClassNames() {
        return classNames({
            'big_video': true,
            'big_video_is_visible': this.state.end_animation,
            'home': this.props.home,
            'hide_big_video': this.state.hide_big_video,
        });
    }

    triggerMobileMenu() {
        this.setState({
            mobile_menu: ! this.state.mobile_menu
        });
    }

    closeBigVideo() {
        this.setState({
            show_big_on_front: true,
            hide_big_video: true
        });
    }

    render() {
        console.log(this);
        return(
            <React.Fragment>
                <div className={"rootWrapper background"}>
                    <div className={this.getBrandClassNames()}>
                        <Link to="/">
                            <img src={BrandLogo} alt="loading..." />
                        </Link>
                    </div>
                    <div className={this.getFrameClassNames()}>
                        <div className="topLeft">
                            <TopLeftFrame/>
                        </div>
                        <div className="topRight">
                            <TopRightFrame/>
                        </div>
                        <div className="bottomLeft">
                            <BottomLeftFrame/>
                        </div>
                        <div className="bottomRight">
                            <BottomRightFrame/>
                        </div>
                        <div className="counter">
                            <Counter/>
                        </div>
                    </div>
                    <div>
                        <div  className={this.getBigVideoClassNames()}>
                            {
                                this.state.bigVideo.url !== undefined

                                    ?
                                    <React.Fragment>
                                        <video  controls={true} autoPlay muted >
                                            <source src={this.state.bigVideo.url } type={this.state.bigVideo.mime_type} />
                                        </video>
                                        <div className="video_overlay" ></div>
                                        <div className="video_close" onClick={() => this.closeBigVideo()}>Menu</div>
                                    </React.Fragment>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>
                    <nav className={this.getNavClassNames()}>
                        <ul>
                            <li><Link to="/realisations">Réalisations</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/agence">Agence</Link></li>
                        </ul>
                    </nav>
                    <aside className={this.getAsideClassNames()}>
                        <div className="logo_min">
                            <LogoSimplified/>
                            <Link to={-1} className="return_link">
                                <span>&#60; Retour</span>
                            </Link>
                        </div>
                        <div className={"aside_nav"}>
                            <ul>
                                <li><Link to="/agence" className={classNames({ 'active': this.props.agence })}>Agence</Link></li>
                                <li><Link to="/contact" className={classNames({ 'active': this.props.contact })}>Contact</Link></li>
                                <li><Link to="/realisations" className={classNames({ 'active': this.props.realisations })}>Réalisations</Link></li>
                            </ul>
                        </div>
                        <div className={this.getMobileMenuClassNames()} onClick={() => this.triggerMobileMenu()}>
                            <BurgerMenu/>
                        </div>
                    </aside>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;