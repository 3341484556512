import React from 'react';
import FetchApiService from "../../services/api_call";
import Footer from "../widgets/Footer";

class Contact extends React.Component {

    constructor() {
        super();
        this.state = {
            page: ""
        };
    }

    componentDidMount() {
        (async () => {
            const page=await FetchApiService.getPageBySlug('contact');
            this.setState({
                page: page.data
            });
        })()
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.state.page ?
                        <React.Fragment>
                            <h1 dangerouslySetInnerHTML={{__html: this.state.page.accroche}}></h1>
                            <div className="page_content">
                                <p dangerouslySetInnerHTML={{__html: this.state.page.content}}>
                                </p>
                            </div>
                        </React.Fragment>
                    :
                        " "
                }
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Contact;