import React from 'react';
import FetchApiService from "../../services/api_call";



class CatDescription extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
            prevPhotoCat: ""
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.items[0] || ( this.props.params.photosCat !== this.state.prevPhotoCat )) {
            this.getItems();
        }
    }

    getItems() {
            (async () => {
                const items=await FetchApiService.getPhotosListByType(this.props.params.photosCat);
                this.setState({
                    items: items.data,
                    prevPhotoCat: this.props.params.photosCat
                });
            })()
    }

    render() {
        return(
            this.state.items.length > 0  ?
                <div className="catDescription">
                    <h1>
                        <span dangerouslySetInnerHTML={{__html: this.state.items[0].photo_categories}}>
                        </span>
                    </h1>
                    <p>
                        {this.state.items[0].photo_description}
                    </p>
                </div>
                :
                <div></div>
        );
    }
}

export default CatDescription;