import React from 'react';
import ListShortVideos from "./ListShortVideos";
import { ReactComponent as PlayLogo } from '../../img/play.svg';

class VideoItem extends React.Component {

    constructor() {
        super();
        this.state = {
            videoFile: '',
            videoThumbnail: '',
            fileId: 0,
            viewShortsVideos: false
        };
        this.loadShortVideos = this.loadShortVideos.bind(this);
        this.loadVideo = this.loadVideo.bind(this);
        this.triggerCloseViewer = this.triggerCloseViewer.bind(this);
        this.setThumbnail = this.setThumbnail.bind(this);
    }

    componentDidMount() {
        this.loadVideo(this.props.fileId.video_fichier);
        this.setState({
            fileId: this.props.fileId,
            videoThumbnail: this.props.thumbnail,
        });
    }

    loadShortVideos(id=0){
        const currentState = this.state.viewShortsVideos;
        this.setState({ viewShortsVideos: !currentState });
        currentState &&  this.loadVideo(this.state.fileId.video_fichier)
        currentState &&  this.setState({ videoThumbnail: this.props.thumbnail })
    }

    loadVideo(id){
        this.props.loadVideo(id);
    }

    triggerCloseViewer(){
        this.props.triggerCloseViewer();
    }

    setThumbnail(video){
        this.setState({ videoThumbnail: video });
    }


    render() {
        console.log(this.props)
        return(
            <React.Fragment>
                {

                    this.props.fileId ?
                        <React.Fragment>
                            <div className="videoThumbnail" onClick={this.triggerCloseViewer} >
                                <div className="playLogo"><PlayLogo/></div>
                                <img src={this.state.videoThumbnail.url} alt={this.state.videoThumbnail.alt}/>
                            </div>
                            <div className="videoDescBlock">
                                {this.state.viewShortsVideos ?
                                    <div className="shortVideos">
                                        <ListShortVideos showShortVideos={this.loadVideo} setThumbnail={this.setThumbnail} videos={this.props.versions_courtes}/>
                                    </div>
                                    :
                                    ""
                                }

                                <div className="videoCard">
                                    <h2>{this.props.title}
                                        {this.props.show_versions_courtes ?
                                            this.state.viewShortsVideos ?"(versions courtes)":"(version longue)"
                                            :
                                            ""
                                        }
                                        </h2>
                                    <div className="shortVideosDisclaimer">
                                        {this.props.show_versions_courtes ?
                                            this.state.viewShortsVideos ?
                                                <div><span onClick={this.loadShortVideos}>retour</span></div>
                                               :
                                                <div>Venez découvrir les <span onClick={this.loadShortVideos}>versions courtes</span></div>
                                            :
                                            ""
                                        }
                                    </div>
                                    <p className="distribution">
                                        {this.props.distribution}
                                    </p>
                                    <p className="description">
                                        {this.props.description}
                                    </p>
                                </div>
                            </div>

                        </React.Fragment>
                        :
                        <div>Loading</div>
                }
            </React.Fragment>
        );
    }
}

export default VideoItem;