import React from 'react';
import { Link } from "react-router-dom";
import FetchApiService from "../../services/api_call";

class PhotosTypeTabs extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.params.photosType !== prevProps.params.photosType) {
            this.getItems();
        }
    }

    getItems() {
        (async () => {
            const items=await FetchApiService.getAllTypesByItem(this.props.params.photosItem);
            console.log(items)
            this.setState({
                items: items.data
            });
        })()
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.state.items ?
                        <ul className={"typeTabsNAv"}>
                            {
                                this.state.items.map((item, index) => (
                                    <li className={"typeTab"} key={index}>
                                        <Link to={ '/realisations/photos/' +
                                            this.props.params.photosItem + "/"
                                        + item.slug }>
                                            {item.nom }
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                        :
                        <div></div>
                }
            </React.Fragment>
        );
    }
}

export default PhotosTypeTabs;