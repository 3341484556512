import React from 'react';
import classNames from 'classnames/bind';
import {Link} from "react-router-dom";

class VideosNav extends React.Component {

    constructor() {
        super();
        this.state = {
            isPanelOpen: false
        };
    }

    componentDidMount() {

    }

    getClassNames(taille_typo) {
        return classNames({
            'typo_home': true,
            ['typo_home_' + taille_typo]: true
        });
    }

    render() {
        return(
            <React.Fragment>
                {this.props.clients.map((client, index) => (
                    <div key={client.id} className={this.getClassNames(client.client_taille_typo)}>
                        <Link to={ '/realisations/videos/' + client.slug }>
                            <span dangerouslySetInnerHTML={{__html: client.titre_multiligne}}>
                            </span>
                        </Link>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

export default VideosNav;