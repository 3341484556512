import React from 'react';

class Counter extends React.Component {

    constructor() {
        super();
        this.state = {
            startTS: 0,
            diff: 0,
            suspended: 0,
            interval: 0
        };
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.setState({
            startTS: +new Date() - this.state.suspended,
            interval: requestAnimationFrame(this.tick),
            suspended: 0
        });
    }

    tick(){
        this.setState({
            diff: new Date(+new Date() - this.state.startTS),
            interval: requestAnimationFrame(this.tick)
        });
    }

    addZero(n){
        return n < 10 ? '0' + n : n;
    }


    render() {

        var diff = this.state.diff;
        var hundredths = diff ? Math.round(this.state.diff.getMilliseconds()/10) : 0;
        var seconds = diff ? this.state.diff.getSeconds() : 0;
        var minutes = diff ? this.state.diff.getMinutes() : 0;

        if (hundredths === 100) hundredths = 0;

        return(
            <React.Fragment>
                00:{this.addZero(minutes)}:{this.addZero(seconds)}:{this.addZero(hundredths)}
            </React.Fragment>
        );
    }
}

export default Counter;