import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames/bind';

class Realisations extends React.Component {

    constructor() {
        super();
        this.state = {
            nav_photos_videos_on: false
        };
    }

    componentDidMount() {
    }

    getNavClassNames() {
        return classNames({
            'nav_photos_videos': true,
            'nav_photos_videos_on': this.props.realisations,
        });
    }

    render() {
        return(
            <React.Fragment>
                <nav className={this.getNavClassNames()}>
                    <ul>
                        <li><Link to="/realisations/videos">Vidéos</Link></li>
                        <li><Link to="/realisations/photos">Photos</Link></li>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}

export default Realisations;