import React from 'react';

class VideoPlayer extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            videoFile: nextProps.videoFile
        });
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.props.videoFile ?
                        <video src={this.state.videoFile.source_url} type={this.state.videoFile.mime_type} controls={true} autoPlay muted ></video>
                        :
                        <div>Loading</div>
                }
            </React.Fragment>
        );
    }
}

export default VideoPlayer;