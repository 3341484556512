import React from 'react';
import FetchApiService from "../../services/api_call";
import PhotoSlider from "./PhotoSlider"

class PhotosCat extends React.Component {

    constructor() {
        super();
        this.state = {
            items: [],
            prevCat: ""
        };
    }

    componentDidMount() {
        this.getItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.params.photosCat !== prevProps.params.photosCat) {
            this.getItems();
        }
    }

    getItems() {
        if (  this.props.params.photosType ) {
            (async () => {
                const items=await FetchApiService.getPhotosListByType(this.props.params.photosCat);
                this.setState({
                    items: items.data,
                    prevCat: this.props.params.photosCat
                });
            })()
        }
    }

    render() {
        return(
            <React.Fragment>
                {
                    this.state.items.length > 0 && this.props.params.photosCat !== this.state.photosCat?
                            <div className="photosCatItem">
                                <div className={"photoSlider"}>
                                    <PhotoSlider { ... this.props } />
                                </div>
                            </div>
                            :
                            <div></div>
                }
            </React.Fragment>
        );
    }
}

export default PhotosCat;